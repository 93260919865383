import React, { Component } from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-dom'; // Import from react-router-dom
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Bhakti_Songs from './Bhakti_Songs';
import VyasJi_Book from './VyasJi_Book';
import AuraHomePage from './auraHomePage';
import CardDetails from './CardDetails';
import cardData from './cardData.json'
import ShrimadBhagwadGeetaJi from './ShrimadBhagwadGeetaJi';
import Shiva from './Shiva'
import Science_And_Saints from './ScienceAndSaints';
// import axios from 'axios';
// import {Route, Switch} from 'react-router-dom'
class App extends Component {
    render() { 
        return (
            // Add :import { BrowserRouter as Router, Routes, Route } from 'react- -dom'; // Import from react-router-dom
            //Remove : import {Route, Switch} from 'react-router-dom'
            // Replace component by element
            // Wrap inside <Router>
            // Home and About in closing tags <Home/>
            // Route also in closing tags
            //Remove State
            //Add: import ReactDOM from 'react-dom';
        // {/*Also  install react-dom by npm install react-dom

            <Router>
            <div>
                <Navbar/>
                <Routes>
                    <Route path='' exact element={<Home/>}/>
                    <Route path='/Bhakti_Songs' element={<Bhakti_Songs/>}/>
                    <Route path='/VyasJi_Book' element={<VyasJi_Book/>}/>
                    <Route path='/auraHomePage' element={<AuraHomePage/>}/>
                    <Route path='/ShrimadBhagwadGeetaJi' element={<ShrimadBhagwadGeetaJi/>}/> 
                    <Route path="/CardDetails/:cardId" element={<CardDetails />}/>
                    <Route path='/ScienceAndSaints' element={<Science_And_Saints/>}/>
                    <Route path='/Shiva' element={<Shiva/>}/>
                </Routes>
            </div>
            </Router>
        );
    }
}
 
export default App;