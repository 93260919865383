import React, { Component } from 'react';
import YtCardPlayer from './YtCardPlayer';
import './YtCardPlayerStyle.scss'
class ShrimadBhagwadGeetaJi extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <br/><br/>
                <center><h4>  (Hindi) 
            </h4></center>
                <YtCardPlayer videoLink="https://www.youtube-nocookie.com/embed/eC5XtmfZuWg" backgroundImage="https://i3.ytimg.com/vi/eC5XtmfZuWg/maxresdefault.jpg"/>
                <br/><br/>
                <center><h4> (English) 
            </h4></center>
                <YtCardPlayer videoLink="https://www.youtube-nocookie.com/embed/zFtdEpHRFGE" backgroundImage= "https://i3.ytimg.com/vi/zFtdEpHRFGE/maxresdefault.jpg"/>
            </div>
        );
    }
}
 
export default ShrimadBhagwadGeetaJi;