import React, { Component } from 'react';
import YtCardPlayer from './YtCardPlayer';
import './YtCardPlayerStyle.scss'

class Shiva extends Component {
    state = {  } 
    render() { 
        return (
            <>
            <br/><br/>
            <center><h1> Shivlinga (Hindi) by Ishaputra
            </h1></center>
            <YtCardPlayer videoLink="https://www.youtube-nocookie.com/embed/sMQSswUNAPg" backgroundImage="https://i3.ytimg.com/vi/sMQSswUNAPg/maxresdefault.jpg"/>
            <br/><br/>
            <center><h1>Shiva Shiva Shambho
            </h1></center>
            <YtCardPlayer videoLink="https://www.youtube-nocookie.com/embed/Hz0a5SXHuBg" backgroundImage="https://i3.ytimg.com/vi/Hz0a5SXHuBg/maxresdefault.jpg"/> 
            <br/>
            <br/>
            <center><h1>Om Namah Shivaya - Krishna Das Live! Songs With Lyrics
            </h1></center>
            <YtCardPlayer videoLink="https://www.youtube-nocookie.com/embed/PTc8X37oJBE" backgroundImage="https://i3.ytimg.com/vi/PTc8X37oJBE/hqdefault.jpg"/>
            <br/><br/>
            <center><h1>Lord Shiva Most Powerful Mantra (Shiva Namaskaratha Mantra)
            </h1></center>
            <YtCardPlayer videoLink="https://www.youtube-nocookie.com/embed/KIz5SbuK9Oc" backgroundImage="https://i3.ytimg.com/vi/KIz5SbuK9Oc/hqdefault.jpg"/> 
            <br/><br/>
            <center><h1>Shiv Tandav Stotram (Sampurna)
            </h1></center>
            <YtCardPlayer videoLink="https://www.youtube-nocookie.com/embed/_zTHQPYHJm8" backgroundImage="https://i3.ytimg.com/vi/_zTHQPYHJm8/maxresdefault.jpg"/> 
            <br/><br/>
            <center><h1>Song of Gratitude
            </h1></center>
            <YtCardPlayer videoLink="https://www.youtube-nocookie.com/embed/euAQ3TNz3yo" backgroundImage="https://i3.ytimg.com/vi/euAQ3TNz3yo/maxresdefault.jpg"/> 
            <br/><br/>
            <center><h1>Rudrashtakam (Ameya Records) श्रीरुद्राष्टकम्
            </h1></center>
            <YtCardPlayer videoLink="https://www.youtube-nocookie.com/embed/DCSH3iKdjSs" backgroundImage="https://i3.ytimg.com/vi/DCSH3iKdjSs/maxresdefault.jpg"/> 
            <br/>
            <br/>
            </>
        );
    }
}
 
export default Shiva;